export const dashInfoMap = {
    playerOverview: `
        The Overview graph shows data as a <strong>score on 10</strong>, or as a <strong>measurement</strong>.
        An <strong>average</strong> value is aggregated <strong>per pitch</strong>, for <strong>each parameter</strong>, within the selected <strong>date range</strong>.
        For the measurement view, the <strong>standard deviation</strong> is also displayed at the bottom.<br />
        <br />
        This graph is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
        The <strong>global pitch filter and date filter</strong> control all dashboards except the GridView comparison dashboard.<br />
        <br />
        <strong>Hovering</strong> over data will display a tooltip with the info for that data point. <br /> Clicking the <strong>pitch name</strong> in that tooltip will highlight all data for that pitch across the entire graph.
        <br />
        All data can be sorted by hovering over the y-axis and clicking the appearing <strong>sort button</strong>.<br />
        <br />
        Single or multiple elements of the graph can be <strong>selected</strong> by clicking or dragging over multiple data.
        <strong>Hovering</strong> over this selection will show a menu that allows data to be <strong>excluded</strong> or <strong>isolated</strong> from the view, or <strong>sorted</strong> in isolation. <br /> <br />
        Finally, it is also possible to <strong>view and download the data</strong> from this menu.
        These sorts and isolations can be reset by clicking the <strong>reset view</strong> button (next to the download button on the top right side).  This will reload the dashboard and reapply the current filters and controls.
    `,
    playerMonth: `
        The Month dashboard shows data <strong>averages</strong> aggregated <strong>per pitch, per month,</strong> within the selected <strong>date range</strong>.
        The <strong>standard deviation</strong> is also displayed at the bottom. <br /><br />
        Each parameter is assigned their own <strong>monthly</strong> graph, allowing a more detailed view than Overview. The green and yellow value ranges from our <strong>trafficlight</strong> system are displayed as <strong>reference bands</strong>. You can drill down to the days where measurements occurred by hovering over the <strong>month header</strong> and clicking the <strong>[+]</strong> button that appears.  Hover over months header and click the <strong>[-]</strong> button to go back to the monthly view.<br /><br />
        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.<br /><br />
        <strong>Hovering</strong> over data will display a tooltip with the info for that data point. <strong>Clicking</strong> on data and subsequently clicking the <strong>pitch name</strong> in that tooltip will highlight all data for that pitch across the entire graph.<br /><br />
        All data can be sorted by hovering over the y-axis and clicking the appearing <strong>sort button</strong>.<br /><br />
        Single or multiple elements of the graph can be <strong>selected</strong> by clicking or dragging over multiple data. <strong>Hovering</strong> over this selection will show a menu that allows data to be <strong>excluded</strong> or <strong>isolated</strong> from the view, or <strong>sorted</strong> in isolation. Finally, it is also possible to <strong>view and download the data.</strong> <br /><br />
        These detail levels, sorts and isolations can be reset by clicking the <strong>reset view</strong> button.  This will reload the dashboard and reapply the currently selected values for filters and controls.
    `,
    playerDay: `
        The Day dashboard shows daily data overlayed over a single time axis (<strong>Overlay</strong>) or separate time axes for each pitch (<strong>Separate</strong>).<br /><br />
        The Day dashboard shows data <strong>averages</strong> for one <strong>parameter</strong>, aggregated <strong>per pitch, per day</strong>, within the selected <strong>date range</strong>.  The <strong>standard deviation</strong> is also displayed at the bottom within the <strong>separate</strong> view.<br /><br />
        This dashboard uses a <strong>continuous time axis</strong>, which makes suited for analyses of changes over time. This axis has labels for the start of each month. Check the tooltips of the data for exact datetimes.<br /><br />
        Per default, data is aggregated <strong>per day</strong>, meaning multiple measurements done on one day will show up as one average.  If needed, you can increase the detail to an hourly or even a minute timescale by clicking the <strong>[+]</strong> button after hovering over the time axis.  This will show individual analyses done within the same day.  Click the <strong>[-]</strong> button to go back to a daily detail level. The time detail can be seen in the tooltip when hovering over the line graph.<br /><br />
        The <strong>dots</strong> at the bottom of the dashboard will display the <strong>grid averages</strong> for this point as a tooltip.<br /><br />
        This dashboard is filtered by the <strong>parameter filter</strong>,  the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.<br /><br />
        <strong>Hovering</strong> over data will display a tooltip with the info for that data point. <strong>Clicking</strong> on data and subsequently clicking the <strong>pitch name</strong> in that tooltip will highlight all data for that pitch across the entire graph.<br /><br />
        Single or multiple elements of the graph can be <strong>selected</strong> by clicking or dragging over multiple data. <strong>Hovering</strong> over this selection will show a menu that allows data to be <strong>excluded</strong> or <strong>isolated</strong> from the view. Finally, it is also possible to <strong>view and download the data</strong>.  Isolations and detail levels can be reset by clicking the <strong>reset view</strong> button.
    `,
    playerZone: `
        The Grid View dashboard shows data <strong>averages</strong> over <strong>time</strong> for each <strong>grid zone</strong>.
        It shows one <strong>parameter</strong> and <strong>pitch</strong>, within the selected <strong>date range</strong>.
        The average at the bottom includes all grid zones and measurements into
        one average. The <strong>standard deviation</strong> at the bottom is the average of
        standard deviations calculated per analysis.
        Finally, the compass denotes the North side.
        <br /><br />
        This dashboard is filtered by the <strong>parameter filter</strong>, its own <strong>single pitch filter</strong>
        and the (global) <strong>date filter</strong>. The <strong>single pitch filter</strong> only controls this dashboard.
        <br /><br />
        The <strong>compare pitches</strong> button closes and opens the second grid view with independent
        controls.  The Compare Pitches dashboard is functionally identical to the Grid View,
        but has its own isolated controls for <strong>date, pitch</strong> and <strong>parameter</strong>.
        Once the <strong>date</strong> filter is used,
        it will function independently from the global date filter.
        <br /><br />
        This allows for comparison of different pitches, different times,
        different parameters or all of the above.
        <br /><br />
        <strong>Hovering</strong> over data shows the number of measurements included.
        To <strong>view and download data</strong>, click somewhere in the colored view and
        deselect your selection if you have one.  Then use the download button.
    `,
    playerPerception: `
        The Perception dashboard shows average scores on 10 from logged perception event data for <strong>stability, firmness</strong> and <strong>ball roll</strong>.
        <br /><br />
        Data is displayed on a <strong>monthly</strong> timescale, which can be drilled down to <strong>days</strong> by clicking the
        <strong>[ + ]</strong> button which appears at the start of the bottom header.  To drill back up to months, click the <strong>[ - ]</strong> button which appears over the top time header.
        <br /><br />
        The <strong>Total / Breakdown</strong>  control switches the view between totals and separate scores for stability, firmness and ball roll.
        <br /><br />
        The tooltips display a more detailed list of average scores given (for these 3 parameters) per player (player number <strong>PL#</strong>).
    `,
    playerIndex: `
        The Player Index dashboard shows average Player Index values for multiple pitches.
        <br /><br />
        Data is displayed on a <strong>monthly</strong> timescale, which can be drilled down to individual <strong>days</strong> by clicking the <strong>[+]</strong> button which appears over its header.  To drill back up to months, click the <strong>[-]</strong> button which appears over the months header.
        <br /><br />
        <strong>Pitches</strong> and <strong>dates</strong> are filtered by the global filters.
        <br /><br />
        The tooltips display a breakdown of these 3 index values on a grid view.
        <br /><br />
        To highlight all bars of a certain Index type, click the magnifier icon. To clear this highlight, click <strong>x</strong>.
    `,
    playerInjuryIndex: `
        The Injury Index dashboard shows average Injury Index values over time for multiple pitches.
        <br /><br />
        Data is displayed on a <strong>monthly</strong> timescale, which can be drilled down to individual <strong>days</strong> by clicking the <strong>[+]</strong> button which appears over its header.  To drill back up to months, click the <strong>[-]</strong> button which appears over the months header.
        <br /><br />
        <strong>Pitches</strong> and <strong>dates</strong> are filtered by the global filters.
        <br /><br />
    `,
    playerBootIndex: `
        The Boot Index dashboard shows suggested boot choice for multiple pitches.
        <br /><br />
        Data is displayed on a <strong>monthly</strong> timescale, which can be drilled down to individual <strong>days</strong> by clicking the <strong>[+]</strong> button which appears over its header.  To drill back up to months, click the <strong>[-]</strong> button which appears over the months header.
        <br /><br />
        <strong>Pitches</strong> and <strong>dates</strong> are filtered by the global filters.
        <br /><br />
    `,
    pitchOverview: `
        The Overview graph shows data as a <strong>score on 10</strong>, or as a <strong>measurement</strong>.
        An <strong>average</strong> value is aggregated <strong>per pitch</strong>, for <strong>each parameter</strong>, within the selected <strong>date range</strong>.
        For the measurement view, the <strong>standard deviation</strong> is also displayed at the bottom.<br />
        <br />
        This graph is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
        The <strong>global pitch filter and date filter</strong> control all dashboards except the GridView comparison dashboard.<br />
        <br />
        <strong>Hovering</strong> over data will display a tooltip with the info for that data point. <br /> Clicking the <strong>pitch name</strong> in that tooltip will highlight all data for that pitch across the entire graph.
        <br />
        All data can be sorted by hovering over the y-axis and clicking the appearing <strong>sort button</strong>.<br />
        <br />
        Single or multiple elements of the graph can be <strong>selected</strong> by clicking or dragging over multiple data.
        <strong>Hovering</strong> over this selection will show a menu that allows data to be <strong>excluded</strong> or <strong>isolated</strong> from the view, or <strong>sorted</strong> in isolation. <br /> <br />
        Finally, it is also possible to <strong>view and download the data</strong> from this menu.
        These sorts and isolations can be reset by clicking the <strong>reset view</strong> button (next to the download button on the top right side).  This will reload the dashboard and reapply the current filters and controls.
    `,
    pitchMonth: `
        The Month dashboard shows data <strong>averages</strong> aggregated <strong>per pitch, per month,</strong> within the selected <strong>date range</strong>.
        The <strong>standard deviation</strong> is also displayed at the bottom. <br /><br />
        Each parameter is assigned their own <strong>monthly</strong> graph, allowing a more detailed view than Overview. The green and yellow value ranges from our <strong>trafficlight</strong> system are displayed as <strong>green and grey reference bands</strong> respectively. You can drill down to the days where measurements occurred by hovering over the <strong>month header</strong> and clicking the <strong>[+]</strong> button that appears.  Hover over months header and click the <strong>[-]</strong> button to go back to the monthly view.<br /><br />
        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.<br /><br />
        <strong>Hovering</strong> over data will display a tooltip with the info for that data point. <strong>Clicking</strong> on data and subsequently clicking the <strong>pitch name</strong> in that tooltip will highlight all data for that pitch across the entire graph.<br /><br />
        All data can be sorted by hovering over the y-axis and clicking the appearing <strong>sort button</strong>.<br /><br />
        Single or multiple elements of the graph can be <strong>selected</strong> by clicking or dragging over multiple data. <strong>Hovering</strong> over this selection will show a menu that allows data to be <strong>excluded</strong> or <strong>isolated</strong> from the view, or <strong>sorted</strong> in isolation. Finally, it is also possible to <strong>view and download the data.</strong> <br /><br />
        These detail levels, sorts and isolations can be reset by clicking the <strong>reset view</strong> button.  This will reload the dashboard and reapply the currently selected values for filters and controls.
    `,
    pitchDay: `
        The Day dashboard shows daily data overlayed over a single time axis (<strong>Overlay</strong>) or separate time axes for each pitch (<strong>Separate</strong>).<br /><br />
        The Day dashboard shows data <strong>averages</strong> for one <strong>parameter</strong>, aggregated <strong>per pitch, per day</strong>, within the selected <strong>date range</strong>.  The <strong>standard deviation</strong> is also displayed at the bottom within the <strong>separate</strong> view.<br /><br />
        This dashboard uses a <strong>continuous time axis</strong>, which makes suited for analyses of changes over time. This axis has labels for the start of each month. Check the tooltips of the data for exact datetimes.<br /><br />
        Per default, data is aggregated <strong>per day</strong>, meaning multiple measurements done on one day will show up as one average.  If needed, you can increase the detail to an hourly or even a minute timescale by clicking the <strong>[+]</strong> button after hovering over the time axis.  This will show individual analyses done within the same day.  Click the <strong>[-]</strong> button to go back to a daily detail level. The time detail can be seen in the tooltip when hovering over the line graph.<br /><br />
        The <strong>dots</strong> at the bottom of the dashboard will display the <strong>grid averages</strong> for this point as a tooltip.<br /><br />
        This dashboard is filtered by the <strong>parameter filter</strong>,  the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.<br /><br />
        <strong>Hovering</strong> over data will display a tooltip with the info for that data point. <strong>Clicking</strong> on data and subsequently clicking the <strong>pitch name</strong> in that tooltip will highlight all data for that pitch across the entire graph.<br /><br />
        Single or multiple elements of the graph can be <strong>selected</strong> by clicking or dragging over multiple data. <strong>Hovering</strong> over this selection will show a menu that allows data to be <strong>excluded</strong> or <strong>isolated</strong> from the view. Finally, it is also possible to <strong>view and download the data</strong>.  Isolations and detail levels can be reset by clicking the <strong>reset view</strong> button.
    `,
    pitchZone: `
        The Grid View dashboard shows data <strong>averages</strong> over <strong>time</strong> for each <strong>grid zone</strong>.
        It shows one <strong>parameter</strong> and <strong>pitch</strong>, within the selected <strong>date range</strong>.
        The average at the bottom includes all grid zones and measurements into
        one average. The <strong>standard deviation</strong> at the bottom is the average of
        standard deviations calculated per analysis.
        Finally, the compass denotes the North side.
        <br /><br />
        This dashboard is filtered by the <strong>parameter filter</strong>, its own <strong>single pitch filter</strong>
        and the (global) <strong>date filter</strong>. The <strong>single pitch filter</strong> only controls this dashboard.
        <br /><br />
        The <strong>compare pitches</strong> button closes and opens the second grid view with independent
        controls.  The Compare Pitches dashboard is functionally identical to the Grid View,
        but has its own isolated controls for <strong>date, pitch</strong> and <strong>parameter</strong>.
        Once the <strong>date</strong> filter is used,
        it will function independently from the global date filter.
        <br /><br />
        This allows for comparison of different pitches, different times,
        different parameters or all of the above.
        <br /><br />
        <strong>Hovering</strong> over data shows the number of measurements included.
        To <strong>view and download data</strong>, click somewhere in the colored view and
        deselect your selection if you have one.  Then use the download button.
    `,
    pitchSoil: `
        The Soil Nutrient dashboard shows <strong>laboratory</strong> test data over <strong>time</strong> alongside <strong>fertilising events</strong> for a selected <strong>pitch</strong>. <br /><br />
        This dashboard is filtered by the (global) <strong>date</strong> filter and its own <strong>single pitch</strong> filter. <br /><br />
        <strong>Nutrient amounts</strong> are displayed by <strong>line graphs</strong>, while <strong>marks</strong> at the bottom of the graph display logged <strong>fertilising events</strong>. <br /><br />
        <strong>Shift-click</strong> to select a range categories on the left, and <strong>ctrl-click</strong> to toggle select single categories.  They can be filtered by selecting <strong>"keep only"</strong> or <strong>"exclude"</strong> from the popup menu. <br /><br />
        Selections can be reverted by clicking the <strong>"Reset"</strong> button. <br /><br />
        Liquid fertiliser is displayed by a <strong>hollow</strong> line, while <strong>granular</strong> applications are solid lines.  Hover over the marks to receive additional info such as NPK amounts.
    `,
    calendarOverview: `
        The Overview dashboard shows the <strong>number of events</strong> for a given pitch or event type. These numbers can be shown <strong>per event</strong>, <strong>per pitch</strong>, or as a <strong>total</strong>. <br /><br />

        Hover over the left axis and click the appearing <strong>sort</strong> button to sort differently within each section.<br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    calendarTimeline: `
        The Timeline dashboard shows the <strong>number of events</strong> per <strong>day or month</strong>. <br /><br />
        Hover over the months header and click the <strong>[-]</strong> button to switch to <strong>monthly totals</strong>. This is recommended for viewing longer timeframes. The <strong>[+]</strong> button switches the view back to <strong>daily totals</strong>.<br /><br />

        These numbers can be shown for <strong>all pitches</strong> (compare pitches) for <strong>one event</strong>, or for <strong>all events</strong> (compare events) of <strong>one pitch</strong>.  <br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    calendarGreenkeepingStats: `
        This table shows the <strong>NPK totals</strong>, weight totals for <strong>clippings, seeding</strong> and <strong>grooming</strong>, <strong>divoting time</strong> in hours, and total <strong>number of diseases</strong> and <strong>biotreatments</strong>. <br /><br />

        These numbers can be shown as <strong>Totals</strong> for the selected timeframe, or <strong>Monthly</strong>.  <br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    calendarMatch: `
        The Match dashboard shows <strong>matches per pitch</strong> sectioned by <strong>team</strong>.<br /><br />

        When a single pitch is selected via the <strong>dropdown</strong> control, a detailed <strong>timeline</strong> and <strong>grid view</strong> is displayed for that pitch. <br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    calendarTraining: `
        The Training dashboard shows <strong>trainings per pitch</strong> sectioned by <strong>team</strong> and/or <strong>training type</strong>.<br /><br />

        When a single pitch is selected via the <strong>dropdown</strong> control, a detailed <strong>timeline</strong> and <strong>grid view</strong> is displayed for that pitch. <br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    calendarAeration: `
        The Aeration dashboard shows <strong>Aerations per pitch</strong> sectioned by <strong>type</strong> and/or <strong>heive</strong> and/or <strong>spacing</strong>.<br /><br />

        When a single pitch is selected via the <strong>dropdown</strong> control, a detailed <strong>timeline</strong> is displayed for that pitch. <br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    calendarBiotreatment: `
        The Biotreatment dashboard shows <strong>Biotreatments per pitch</strong> sectioned by <strong>type</strong> and/or <strong>product</strong>.<br /><br />

        When a single pitch is selected via the <strong>dropdown</strong> control, a detailed <strong>timeline</strong> is displayed for that pitch. <br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    calendarDisease: `
        The Disease dashboard shows <strong>Diseases per pitch</strong> sectioned by <strong>disease type</strong>.<br /><br />

        When a single pitch is selected via the <strong>dropdown</strong> control, a detailed <strong>timeline</strong> and <strong>grid view</strong> is displayed for that pitch. <br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    calendarDivoting: `
        The Divoting dashboard shows <strong>event counts</strong> or <strong>hours logged</strong> per pitch.<br /><br />

        When a single pitch is selected via the <strong>dropdown</strong> control, a detailed <strong>timeline</strong> and <strong>grid view</strong> is displayed for that pitch. <br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    calendarFertilising: `
        The Fertilising dashboard shows <strong>events logged</strong> or <strong>NPK amounts</strong> per pitch, sectioned by <strong>type</strong>.<br /><br />

        When a single pitch is selected via the <strong>dropdown</strong> control, a detailed <strong>timeline</strong> is displayed for that pitch. <br /><br />

        This timeline displays <strong>daily</strong> NPKs per default, but can be switched to <strong>monthly</strong> totals by clicking the <strong>[-]</strong> button after hovering on the months header.<br /><br />

        Alternatively, it can be switched to <strong>accumulated totals</strong> over time by selecting the 'accumulated' option in the rightmost dropdown menu.<br /><br />

        Per default N, P and K are all shown. By clicking the dropdown menu 'NPK', a single element (N, P or K) can be isolated in view.<br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    calendarGrooming: `
        The Grooming dashboard shows <strong>events logged</strong> or <strong>weight logged</strong> per pitch, sectioned by <strong>type</strong> and/or <strong>direction</strong>.<br /><br />

        When a single pitch is selected via the <strong>dropdown</strong> control, a detailed <strong>timeline</strong> is displayed for that pitch. <br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    calendarHeating: `
        The Heating dashboard shows <strong>heating events</strong> per pitch, sectioned by <strong>type</strong> and/or <strong>temperature</strong>.<br /><br />

        When a single pitch is selected via the <strong>dropdown</strong> control, a detailed <strong>timeline</strong> is displayed for that pitch. <br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    calendarMowing: `
        The Mowing dashboard shows <strong>events, clipping volume</strong> or <strong>clipping height</strong>, sectioned by <strong>machine, direction</strong> and/or <strong>technique</strong>.<br /><br />

        When a single pitch is selected via the <strong>dropdown</strong> control, a detailed <strong>timeline</strong> is displayed for that pitch. <br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    calendarSeeding: `
        The Seeding dashboard shows <strong>events logged</strong> or <strong>weight logged</strong>, sectioned by <strong>type</strong>.<br /><br />

        When a single pitch is selected via the <strong>dropdown</strong> control, a detailed <strong>timeline</strong> is displayed for that pitch. <br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    calendarIrrigation: `
        The Irrigation dashboard shows <strong>events logged</strong> or <strong>millimeters logged</strong>.<br /><br />

        When a single pitch is selected via the <strong>dropdown</strong> control, a detailed <strong>timeline</strong> and <strong>grid view</strong> is displayed for that pitch. <br /><br />

        When <strong>events logged</strong> is selected, the timeline and zones will display the sum of events. To view the sum of millimeters instead, select <strong>millimeters logged</strong>.<br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    calendarLinemarking: `
        The Line Marking dashboard shows <strong>events logged</strong>, <strong>volume logged</strong> or <strong>time logged</strong>.<br /><br />

        When a single pitch is selected via the <strong>dropdown</strong> control, a detailed <strong>timeline</strong> is displayed for that pitch. <br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    calendarTopdressing: `
        The Top Dressing dashboard shows <strong>events logged</strong>, <strong>weight logged</strong> or <strong>time logged</strong>.<br /><br />

        When a single pitch is selected via the <strong>dropdown</strong> control, a detailed <strong>timeline</strong> is displayed for that pitch. <br /><br />

        This dashboard is filtered by the (global) <strong>date filter</strong> and (global) <strong>pitch filter</strong> controls on the left-hand side of the page.
    `,
    weatherIndex: `
        The Weather Indices dashboard shows the <strong>Growth Potential Index</strong> and <strong>Disease Risk Index</strong>. <br /><br />
        Growth Potential is calculated from <strong>light</strong> amounts (DLI) and <strong>air temperature</strong>.  <br /><br />
        When both are favorable, Growth Potential will increase (to a max value of 10, indicating 100% growth potential).
        When either or both conditions are unfavorable, Growth Potential will decrease (to a minimum value of 0). <br /><br />

        The <strong>Disease Risk</strong> Index is calculated from the cumulative contributions of high <strong>temperatures</strong>, high <strong>humidity</strong> and low <strong>wind speeds</strong>.  <br /><br />
        A high score for the Disease Risk Index (max of 10) indicates a warm, moist, badly ventilated environment, conducive to most common <strong>mold</strong> formation and <strong>bacterial</strong> growth.
        Take note that diseases that thrive in cold conditions are not accounted for in this calculation. <br /><br />

        If a measurement fails or the station is turned off, the temperature, humidity and wind speeds from the local weather forecast are used instead as a fallback.
        There is no fallback for DLI (and in turn also growth potential).
    `,
    weatherTemperature: `
        The Temperature dashboard shows a temperature graph with <strong>maximum</strong>, <strong>minimum</strong> and <strong>average</strong> daily temperatures, and a <strong>growing degree-days</strong> (GDD) chart. <br /><br />

        GDD are a measure of <strong>heat accumulation</strong> used to predict <strong>plant development</strong> rates.
        Growing degrees are the number of degrees that the <strong>daily average</strong> temperature <strong>exceeds</strong> a chosen <strong>base</strong> temperature value (Tb). This base temperature is also displayed on the temperature graph. <br /><br />

        The value for <strong>Tb</strong> can be adjusted by typing a new value (between 0 and 20) within the controls section of this dashboard. <br /><br />

        The growing degrees for each day are represented by the <strong>left side axis</strong> and <strong>bars</strong> on the chart.
        The total Growing Degree-days (GDD) is the running sum of these values per day, represented by the <strong>right side axis</strong> and the <strong>line graph</strong>.<br /><br />

        If a measurement fails or the station is turned off, the temperature from the local weather forecast is used instead as a fallback.
    `,
    weatherLight: `
        The Light dashboard shows a <strong>Daily Light Integral</strong> (DLI), <strong>Solar Radiation</strong> and <strong>UV Index</strong> graph. <br /><br />
        <strong>DLI</strong> describes the number of <strong>photosynthetically active</strong> photons (individual particles of light in the 400-700 nm range) that are delivered to a specific area over a 24-hour period.
        The <strong>long bars</strong> represent the <strong>DLI</strong> that was measured by the weather station.  The darker, <strong>shorter bars</strong> are 40% of this full DLI measurement. They represent an <strong>estimation</strong> of DLI for <strong>shadowy areas</strong>. <br /><br />
        The <strong>Solar Radiation</strong> graph represents the <strong>total</strong> light energy of <strong>any and all</strong> photons delivered to a specific area, expressed in Watts per square meter. <br /><br />
        The <strong>ultraviolet index</strong>, or UV index, is an international standard measurement of the <strong>strength</strong> of the <strong>sunburn-producing</strong> ultraviolet <strong>radiation</strong> at a particular place and time.<br /><br />

        If a measurement fails or the station is turned off, the UV index from the local weather forecast is used instead as a fallback.
        There is no fallback for solar radiation (and DLI, which is calculated from solar radiation).
    `,
    weatherWater: `
        The Water Presence dashboard shows <strong>precipitation</strong> and <strong>relative humidity</strong>. <br /><br />

        The <strong>Precipitation</strong> graph shows bars for daily precipitation quantified on the <strong>left axis</strong>.
        The <strong>right axis</strong> provides a scale for the <strong>line graph</strong>, which represents a <strong>running total</strong> of precipitation <strong>accumulated</strong> since the first day in the view.<br /><br />

        The <strong>Humidity</strong> graph shows <strong>daily average</strong> relative humidity.  Days that include at least one hour of near-saturation (dew) humidity (near 100%) are highlighted. <br /><br />
        To investigate a single day further, <strong>select</strong> that bar and select <strong>'keep only'</strong>.  Then click the <strong>[+]</strong> button that appears on the left side when <strong>hovering</strong> the pointer over the <strong>time axis</strong> on the bottom of the graph.
        The day is now split up into separate bars per hour. <br /><br />

        If a measurement fails or the station is turned off, humidity from the local weather forecast is used instead as a fallback.
        There is no fallback for precipitation.
    `,
    weatherVentilation: `
        The Ventilation dashboard shows <strong>wind speed</strong> and <strong>pressure</strong>. <br /><br />

        The <strong>Wind Speed</strong> graph shows maximum and average values in meters per second.
        The <strong>Pressure</strong> graph shows maximum, average and minimum values in millibar.  <br /><br />

        If a measurement fails or the station is turned off, wind speeds from the local weather forecast are used instead as a fallback.
        There is no fallback for pressure.
    `,
    weatherUptime: ``
}