import React, { useContext, useMemo } from 'react'
import { css } from '@emotion/react'
import infoImg from '@assets/icons/infoImage.svg'
import AnalyticsContext from '../../../context/Analytics'
import { colors } from '../../../style/vars'
import sanitize from 'dompurify'

export default function Info({ infoHtml, ...props }) {

    const {dashboards: { setInfoModal }} = useContext(AnalyticsContext)

    const cleanHtml = useMemo(() => {
        return sanitize(infoHtml || '')
    }, [infoHtml])

    return (
        <div>
            <div
                css={buttonStyle}
                onClick={() => {
                    setInfoModal(
                        <p dangerouslySetInnerHTML={{ __html: cleanHtml }} />
                    )
                }}
            >
                <img src={infoImg} />
            </div>
        </div>
    )
}

const buttonStyle = css`
    border-radius: 4px;
    background-color: ${colors.eventDark};
    border: 0px;
    margin-left: 1px;
    width: 32px;
    height: 32px;
    margin-top: 1px;
    margin-bottom: 1px;
    cursor: pointer;

    &:hover {
        opacity: 0.8
    }
`