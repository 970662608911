import React, { useState, useCallback, useEffect, useReducer, useMemo, useContext } from 'react'
import { getUnixTime } from 'date-fns'
import { css } from '@emotion/react'
import QuestionMark from '@assets/icons/question.svg'
import Close from '@assets/icons/no.svg'
import EventDateTimePicker from '../_control/EventDateTimePicker'
import NumberScroller from '../_control/NumberScroller'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import HappinessWidget from '../_control/HappinessWidget'
import { ReactSVG } from 'react-svg'
import { colors } from '../../style/vars'
import FormLoader from './FormLoader'
import { FormattedMessage, useIntl } from 'react-intl'
import PitchContext from '../../context/Pitch'
import sanitize from 'dompurify'

export default function PlayerPerceptionForm({
    eventType,
    event,
    onPostRequest
}) {
    //utils
    const { post, put, get } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [comment, setComment] = useState('')

    const [pPerceptions, dispatch] = useReducer((state, { action, id, prop, value }) => {
        switch (action) {
            case "add":
                const highestId = state.reduce((maxId, { id }) => (Math.max(maxId, id)), -1)
                return [
                    ...state, {
                        id: highestId + 1,
                        playerNumber: 1,
                        firmnessScore: null,
                        stabilityScore: null,
                        ballRollAndDensityScore: null
                    }]
            case "remove":
                return !!id ? state.filter(({ id: objId }) => objId != id) : state
            case "edit":
                if (!id || !prop) return state
                return state.map(perc => perc.id === id ?
                    { ...perc, [prop]: value }
                    : perc
                )
            case "set":
                return value || state
            default:
                return state
        }
    }, [{
        id: 1,
        playerNumber: 1,
        firmnessScore: null,
        stabilityScore: null,
        ballRollAndDensityScore: null
    }])

    //other
    const [pitch] = useContext(PitchContext)
    const [isLegendOpen, setLegendOpen] = useState(false)
    const [checkUnique, setCheckUnique] = useState(false)
    const [formError, setFormError] = useState()
    const [isLoading, setIsLoading] = useState(!!event)

    const duplicatePlayers = useMemo(() => {
        const numbers = new Set()
        const dupes = []
        pPerceptions?.forEach(perc => {
            !numbers.has(perc.playerNumber) ?
                numbers.add(perc.playerNumber)
                : dupes.push(perc.playerNumber)
        })
        return dupes
    }, [pPerceptions])

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/player-perception-events/${eventId}`)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setComment(sanitize(data?.remarks))
                dispatch({
                    action: "set", value: [...data?.playerPerceptions.map((playerPerception, i) => {
                        return {
                            ...playerPerception,
                            id: i + 1
                        }
                    })]
                })
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    const validateAndSubmit = useCallback(async () => {

        // restrict player perceptions
        if (duplicatePlayers.length) {
            setFormError(formatMessage({ id: 'duplicatePlayerNumbersNotAllowed' }))
            setCheckUnique(true)
            return
        }

        setCheckUnique(false)
        setFormError()
        setIsLoading(true)

        const payload = {
            timestamp: getUnixTime(selectedDateTime),
            remarks: sanitize(comment),
            playerPerceptions: pPerceptions.map(({ id, ...playerPerception }) => playerPerception),
        }

        if (event) {
            const { data, error } = await put(`${event['@id']}`, { body: payload })
            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        } else {
            const newPayload = {
                ...payload,
                name: `Playerperception event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }
            const { data, error } = await post(`/api/current/${eventType.path}`, { body: newPayload })
            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        }
    }, [pPerceptions, comment, selectedDateTime])

    return (<>
        {isLoading ? <FormLoader /> :
            <div css={{ position: 'relative' }}>
                {isLegendOpen &&
                    <div css={styles.legend}>
                        <b><FormattedMessage id='firmness' /></b>
                        <FormattedMessage id='firmnessInfo' /><br /><br />
                        <b><FormattedMessage id='stability' /></b>
                        <FormattedMessage id='stabilityInfo' /><br /><br />
                        <b><FormattedMessage id='ballRollDensity' /></b>
                        <FormattedMessage id='ballRollDensityInfo' />
                    </div>
                }

                <EventDateTimePicker
                    unixDate={selectedDateTime}
                    setUnixDate={setSelectedDateTime}
                />

                <button css={styles.legendToggle}>
                    <ReactSVG onClick={() => setLegendOpen(!isLegendOpen)} src={isLegendOpen ? Close : QuestionMark} css={css`color:#ffffff;`} />
                </button>
                
                {pPerceptions.map(({
                    id,
                    playerNumber,
                    firmnessScore,
                    stabilityScore,
                    ballRollAndDensityScore,
                }) =>
                    <div key={id} css={{
                        position: 'relative',
                        backgroundColor: '#283135',
                        padding: '1em',
                        marginBottom: '1em',
                        borderRadius: '5px',
                        border: (checkUnique && duplicatePlayers.includes(playerNumber))
                            ? '2px solid #DA0E32'
                            : '2px solid transparent'
                    }}>
                        <div css={{ display: 'inline-flex' }}>
                            <NumberScroller
                                label={formatMessage({ id: 'playerNumber' })}
                                minVal={1}
                                maxVal={99}
                                horizontal={true}
                                state={playerNumber}
                                setState={(number) => dispatch({
                                    action: "edit",
                                    id,
                                    prop: "playerNumber",
                                    value: parseInt(number)
                                })}
                            />
                        </div><br /><br />
                        <div css={styles.scores}>
                            <div className="score">
                                <HappinessWidget
                                    label={formatMessage({ id: 'firmness' })}
                                    state={firmnessScore}
                                    setState={(score) =>
                                        dispatch({
                                            action: "edit",
                                            id,
                                            prop: "firmnessScore",
                                            value: score
                                        })}
                                />
                            </div>
                            <div className="score">
                                <HappinessWidget
                                    label={formatMessage({ id: 'stability' })}
                                    state={stabilityScore}
                                    setState={(score) =>
                                        dispatch({
                                            action: "edit",
                                            id,
                                            prop: "stabilityScore",
                                            value: score
                                        })}
                                />
                            </div>
                            <div className="score">
                                <HappinessWidget
                                    label={formatMessage({ id: 'ballRollDensity' })}
                                    state={ballRollAndDensityScore}
                                    setState={(score) =>
                                        dispatch({
                                            action: "edit",
                                            id,
                                            prop: "ballRollAndDensityScore",
                                            value: score
                                        })}
                                />
                            </div>
                        </div>
                        {pPerceptions.length > 1 &&
                            <button css={styles.removeButton} onClick={() => dispatch({ action: "remove", id })} ><span>+</span></button>
                        }
                    </div>
                )}
                <button css={styles.addButton} onClick={(e) => dispatch({ action: "add" })}>+</button>
                <div css={styles.comment}>
                    <label>
                        <FormattedMessage id='comment' />
                    </label>
                    <textarea onChange={(e) => setComment(e.target.value)} value={comment ?? ""}></textarea>
                </div>
            </div >
        }
        {formError && <div>{formError}</div>}
        <button
            className="btn"
            type="button"
            onClick={() => validateAndSubmit()}
            disabled={isLoading}
        >
            <FormattedMessage id='save' />
        </button>
    </>
    )
}

const styles = {
    scores: css`
        display: flex;
        flex-wrap: wrap;

        .score {
            margin-right: 1em;
            margin-bottom: 1em;

            &:last-of-type {
                margin-right: 0;
            }
        }
    `,
    addButton: css`
        display: flex;
        width: 100%;
        border: 2px dashed ${colors.white};
        border-radius: 5px;
        padding: .2em;
        justify-content: center;
        align-items: center;
        color: ${colors.white};
        background-color: transparent;
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 1em;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &:hover {
            border: 2px dashed ${colors.main1};
            color: ${colors.main1};
        }
    `,
    removeButton: css`
        position: absolute;
        right: 1em;
        top: 1em;
        border: none;
        border-radius: 5px;
        height: 20px;
        width: 20px;
        padding: 0;
        background-color: #1e2629;
        cursor: pointer;
        transition: .5s ease-in;

        span {
            transform: rotate(45deg);
            display: block;
            font-weight: bold;
            font-size: 20px;
            color: #55686f;
            margin-top: -1px;
            margin-left: -1px;
            transition: .5s ease-in;
        }

        &:focus {
            outline: none;
            border: none;
        }

        &:hover {
            background-color: #DA0E32;

            span {
                color: ${colors.white}
            }
        }

    `,
    comment: css`
        display: flex;
        flex-direction: column;

        label {
            margin-bottom: .5em;
        }

        textarea {
            resize: vertical;
            background: #283135;
            border: 2px solid #283135;
            border-radius: 5px;
            padding: 1em;
            color: white;
            font-family: Heebo, sans-serif, sans-serif;
            margin-bottom: 1em;

            &:focus {
                border: 2px solid ${colors.main1};
            }
        }
    `,
    legend: css`
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #232628;
        z-index: 100;

        b {
            font-weight: bold;
            margin-bottom: .5em;
            display:block;
        }

        p {
            margin-bottom: 2em;
            line-height: 1.5em;
        }
    `,
    legendToggle: css`
        position: absolute;
        right: 0;
        top: -35px;
        padding: 0;
        margin: 0 0 1em;
        background-color: transparent;
        border: none;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    `,
}

