import React, { useContext, useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/react'
import { NavLink } from 'react-router-dom'
import { colors, fonts, timings } from '../../style/vars'
import Pitch from '../../context/Pitch'
import Analysis from '../../context/Analysis'
import TestCategoryGroupsContext from '../../context/TestCategoryGroup'
import useOption from '../../hooks/useOption'
import PageTitle from '../_general/PageTitle'
import LoaderText from '../_general/LoaderText'
import FallbackMessage from '../_general/FallbackMessage'
import Dropdown from '../_control/Dropdown'
import TestResultCard from '../test/TestResultCard'
import AnalysisSummaryModal from '../modals/AnalysisSummaryModal'
import AnalysisHeader from '../analysis/AnalysisHeader'
import PitchCardWithData from '../pitch/PitchCardWithData'
import Modal from '../_layout/Modal'
import { MTM_LIST_TYPES } from '../../utils/matomo'
import { FormattedMessage, useIntl } from 'react-intl'
import AnalysisDate from '../analysis/AnalysisDate'
import AnalysisDataSummary from '../analysis/AnalysisDataSummary'
import sanitize from 'dompurify'

export default function AnalysisDetails({ ...props }) {
    const [pitch] = useContext(Pitch)
    const [analysis, {
        waiting: analysisWaiting,
        failed: analysisFetchFailed,
        fetch: fetchAnalysis,
    }] = useContext(Analysis)

    const [editSummaryModal, setEditSummaryModal] = useState(false)
    const [compact, setCompact] = useState(false)
    const [pitchModal, setPitchModal] = useState(false)

    const { data: testCategoryGroups } = useContext(TestCategoryGroupsContext)
    const testCategoryFilterOptions = useMemo(() => (
        testCategoryGroups
            ? testCategoryGroups.map(({ code, name }) => ({ value: code, label: name }))
            : []
    ), [testCategoryGroups])

    const [categoryFilter] = useOption('', MTM_LIST_TYPES['testCategoryGroup-select'])

    const tests = useMemo(() => {
        if (analysis && analysis.tests) {
            return (analysis.tests
                .filter(test => !categoryFilter.value || test.testCategory.group === categoryFilter.value)
                .sort((a, b) => a.testCategory.weight - b.testCategory.weight)
            )
        }
    }, [analysis, categoryFilter.value])

    // refresh analysis data after editing zones
    useEffect(() => {
        if (!analysis) return  // dont double fetch here if context will fetch
        fetchAnalysis()
    }, [fetchAnalysis])

    const { formatMessage } = useIntl()

    const sanitizedSummary = useMemo(() => {
        return sanitize(analysis?.summary) || ''
    }, [analysis])

    return (
        <React.Fragment>
            <div {...props}>
                <div css={style.header} >
                    <PageTitle css={style.title}>
                        {pitch
                            ?
                            <NavLink
                                css={css`
                                    text-decoration: none; 
                                    color: inherit;
                                    cursor: ${!!pitch.club?.id ? 'pointer' : 'unset'};
                                `}
                                to={!!pitch.club?.id ? `/club/${pitch.club?.id}` : undefined}>
                                {pitch.club?.name || formatMessage({ id: 'noClub' })}
                            </NavLink>
                            :
                            <LoaderText subtle />
                        }
                        &nbsp;{'/'}&nbsp;
                        {pitch
                            ?
                            <NavLink css={css`text-decoration: none; color: inherit;`} to={`/pitch/${pitch.id}`}>
                                {pitch.name}
                            </NavLink>
                            :
                            <LoaderText subtle />
                        }
                        <span css={css`color: ${colors.main1};`}>
                            &nbsp;{'/'}&nbsp;
                            {analysisFetchFailed
                                ? 'nonexistant'
                                : analysisWaiting
                                    ? <LoaderText subtle />
                                    : <AnalysisDate
                                        date={analysis.date}
                                        timeZone={pitch?.venue?.timeZone}
                                        css={style.analysisDate}
                                    />
                            }
                        </span>
                    </PageTitle>
                    <div css={style.dropdownWrapper}>
                        <span css={style.label}>
                            <FormattedMessage id='showTestCategoryGroups' />
                        </span>
                        <Dropdown
                            css={style.dropdown}
                            options={[
                                {
                                    value: '',
                                    label: formatMessage({ id: 'all' }),
                                },
                                ...testCategoryFilterOptions,
                            ]}
                            {...categoryFilter}
                        />
                    </div>
                </div>
                {!analysisFetchFailed
                    ?
                    <>
                        <AnalysisHeader
                            pitch={pitch}
                            analysis={analysis}
                            compact={compact}
                            setCompact={setCompact}
                            setPitchModal={setPitchModal}
                            setEditSummaryModal={setEditSummaryModal}
                            analysisWaiting={analysisWaiting}
                        />

                        <AnalysisDataSummary analysis={analysis} tests={tests} />

                        <div css={style.testResultsWrapper}>
                            <div css={style.testResults(compact)}
                                id='testResults'
                            >
                                {!analysisWaiting && tests &&
                                    tests.map((test, index) => <TestResultCard
                                        test={test}
                                        showDetails={!compact}
                                        key={index}
                                    />
                                    )
                                }

                                {analysis?.summary && <div css={style.summarySection}>
                                    <PageTitle css={style.summaryTitle}>
                                        {formatMessage({ id: 'summary' })}
                                    </PageTitle>
                                    <div css={style.summary}
                                        onClick={() => setEditSummaryModal(true) }
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: sanitizedSummary }} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </>
                    :
                    <FallbackMessage>
                        <FormattedMessage id='couldNotLoadAnalysis' />
                    </FallbackMessage>
                }

            </div>

            {(editSummaryModal && analysis) &&
                <AnalysisSummaryModal
                    css={css`width: 56em;`}
                    analysis={analysis}
                    onClickClose={() => { setEditSummaryModal(false) }}
                    onSave={() => {
                        setEditSummaryModal(false)
                        fetchAnalysis()
                    }}
                />
            }
            {pitchModal && analysis &&
                <Modal
                    css={css`padding-top: 1em;`}
                    onClickClose={() => setPitchModal(false)}
                >
                    <PitchCardWithData
                        pitch={pitch}
                        venue={pitch?.venue}
                    />
                </Modal>
            }
        </React.Fragment >
    )
}

const style = {
    header: css`
        display: flex; 
        justify-content: space-between; 
        align-items: center; 
        margin-bottom: 2em;
        flex-wrap: wrap;
        gap: 2em;
    `,
    title: css`
        margin-bottom: 0; 
        display: flex; 
        flex-wrap: wrap;
    `,
    analysisDate: css`
        font-family: ${fonts.main};
        font-weight: unset;
        font-size: 1em;
    `,
    dropdownWrapper: css`
        display: flex; 
        align-items:center;
        @media screen and (max-width: 600px) {
            display: none;
        }
    `,
    label: css`
        color: ${colors.subtle}; 
        font-size: 0.875em; 
        font-weight: 300; 
        margin-right: 0.75em;
    `,
    dropdown: css`
        width: 16em;
    `,
    testResultsWrapper: css`
        display: grid;
        grid-template: 1fr / 1fr;
        grid-gap: 0.4em;
    `,
    testResults: (compact) => css`
        height: min-content;
        max-width: 3200px;
        display: grid;
        grid-gap: 0.4em;
        ${!compact ?
            `grid-template-columns: repeat(2, 1fr);
        @media (max-width: 1720px) {
            grid-template-columns: repeat(1, 1fr)
        }`
            :
            `grid-template-columns: repeat(5, 1fr);
        @media (max-width: 2200px) {
            grid-template-columns: repeat(4, 1fr)
        }
        @media (max-width: 1820px) {
            grid-template-columns: repeat(3, 1fr)
        }
        @media (max-width: 1370px) {
            grid-template-columns: repeat(2, 1fr)
        }`}
    `,
    summarySection: css`
        background: ${colors.eventDark};
        padding: 2em;
        border-radius: 5px;
    `,
    summaryTitle: css`
        font-size: 1.25em;
        line-height: 1.2;
        font-family: ${fonts.special};
        font-weight: 700;
        text-transform: uppercase;
    `,
    summary: css`
        background: ${colors.eventLight};
        border-radius: 5px;
        padding: 1em;
        cursor: pointer;
        transition: background ${timings.smooth};

        &:hover {
            background: rgb(43, 80, 85);
        }
    `
}