import React, { useMemo } from 'react'
import { css } from '@emotion/react'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import Test from '../../prop-types/Test'
import { colors } from '../../style/vars'
import AdviceBlock from '../_general/AdviceBlock'
import LoaderText from '../_general/LoaderText'
import getTestCategoryGroupColor from '../../utils/getTestCategoryGroupColor'
import sanitize from 'dompurify'
import { useIntl, FormattedMessage } from 'react-intl'
import capitalize from 'voca/capitalize'

export default function TestsAdvice({ tests, ...props }) {
    const testsWithComment = useMemo(() => (
        tests
            ? tests.filter(test => test.comment || (test.advices && test.advices.length > 0))
            : undefined
    ), [tests])

    const { formatMessage } = useIntl()

    return (
        <AdviceBlock title={formatMessage({ id: 'advice' })} {...props}>
            {testsWithComment
                ?
                testsWithComment.length > 0
                    ?
                    testsWithComment.map((test) => {
                        const testCategoryColor = getTestCategoryGroupColor(test.testCategory.group)
                        return (
                            <div css={style.advice} key={test.id}>
                                {(test.advices && test.advices.length > 0) && test.advices.map(advice => (
                                    <div
                                        key={advice.id}
                                        className='advice comment'
                                        css={css`color: ${testCategoryColor};`}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: sanitize(advice.instruction || '') }} />
                                    </div>
                                ))}
                                {test.comment && <>
                                    <hr css={css`width: 100%; opacity: 0.3;`} />
                                    <h2 css={css`margin: 1em 0; font-size: 1.2em;`}>
                                        {capitalize(formatMessage({ id: 'summary' }).toLowerCase())}
                                    </h2>
                                    <div className='advice'>
                                        <div dangerouslySetInnerHTML={{ __html: sanitize(test.comment || '') }} />
                                    </div>
                                </>}
                            </div>
                        )
                    })
                    :
                    <span css={css`opacity: 0.5; font-size: 0.875em; font-weight: 300;`}>
                        <FormattedMessage id='noAdviceProvidedYet' />
                    </span>
                :
                <LoaderText subtle />
            }
        </AdviceBlock>
    )
}

TestsAdvice.propTypes = {
    tests: PropTypes.arrayOf(Test()),
}

const style = {
    advice: css`
        margin-bottom: 1em;
        padding-bottom: 1em;
        border-bottom: 0.125em solid ${rgba(colors.subtle, 0.25)};
        &:last-of-type {
            border-bottom: none;
            margin-bottom: 0;
        }
        .advice {
            opacity: 0.6;
            font-size: 0.875em;
            margin-bottom: 0.75em;
            line-height: 1.4;
            font-weight: 300;
            text-align: justify;
        }
        .comment {
            opacity: 1;
        }
        .info {
            font-size: 0.75em;
            margin-top: 0.25em;
            margin-bottom: 1.5em;
        }
    `,
}