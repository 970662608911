
import React, { useState, useCallback, useEffect, useContext } from 'react'
import { getUnixTime } from 'date-fns'
import { css } from '@emotion/react'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import FormLoader from './FormLoader'
import EventDateTimePicker from '../_control/EventDateTimePicker'
import { FormattedMessage } from 'react-intl'
import sanitize from 'dompurify'
import WysiwygEditor from '../wysiwyg/WysiwygEditor'
import PitchContext from '../../context/Pitch'

export default function NoteForm({
    eventType,
    event,
    onPostRequest
}) {
    //utils
    const { post, put, get } = useLdjsonApi()

    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [note, setNote] = useState('')
    //other
    const [pitch] = useContext(PitchContext)

    const [formError, setFormError] = useState()
    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/note-events/${eventId}`)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setNote(sanitize(event.note))
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    const validateAndSubmit = useCallback(async () => {
        setIsLoading(true)

        const payload = {
            timestamp: getUnixTime(selectedDateTime),
            note,
        }

        if (event) {
            const { data, error } = await put(`${event['@id']}`, { body: payload })
            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            } else if (data) {
                onPostRequest(true, data)
            }
        } else {
            const newPayload = {
                ...payload,
                name: `Note event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }
            const { data, error } = await post(`/api/current/${eventType.path}`, { body: newPayload })
            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            } else if (data) {
                onPostRequest(true, data)
            }
        }
    }, [pitch, selectedDateTime, note])

    return (<>
        {isLoading ? <FormLoader /> : <>
            <EventDateTimePicker
                unixDate={selectedDateTime}
                setUnixDate={setSelectedDateTime}
            />
            <div css={css`
                display: grid; 
                width: min(50em, 60vw);
            `} >
                <WysiwygEditor
                    css={css`width: 100%;`}
                    value={note}
                    onBlur={(value) => { setNote(value) }}
                />
            </div>
        </>}
        {formError && <div>{formError}</div>}
        <button
            className="btn"
            disabled={isLoading}
            type="button"
            onClick={(e) => validateAndSubmit(e)}>
            <FormattedMessage id='save' />
        </button>
    </>)
}